import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function MinusIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.79688 12H11.9969H19.1969"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default MinusIcon;
