export const HOME_PAGE_FAQ = [
  {
    question: 'What is goalskeeper.io?',
    answer:
      'goalsKeeper.io is a digital marketing analytics platform designed to simplify data analysis for small business digital marketers. It provides effortless insights from your marketing data, helping you make informed decisions and drive business growth.'
  },
  {
    question: 'Who can benefit from using goalskeeper.io?',
    answer:
      "goalskeeper.io is perfect for small business owners, solo digital marketers, and teams. Whether you're managing marketing in addition to other roles or you're a dedicated digital marketing professional, our platform helps you streamline your data analysis and achieve marketing success."
  },
  {
    question: 'What integrations are available on goalskeeper.io?',
    answer:
      'goalskeeper.io currently integrates with popular tools like Google Analytics, Google search console, Gumroad, Mailchimp, and YouTube Analytics. We continuously work on adding more integrations to support your marketing needs.'
  },
  {
    question: 'How does goalskeeper.io help with goal-setting?',
    answer:
      'goalskeeper.io allows you to set specific marketing goals based on your data. The platform provides insights and tracks your progress towards these goals, helping you stay on track and measure your success.'
  },
  {
    question: 'Is goalsKeeper.io easy to use for non-technical users?',
    answer:
      'Absolutely! goalskeeper.io is designed with simplicity in mind. You don’t need to be a data analysis expert to use our platform. Our intuitive interface makes it easy to connect your data sources, create dashboards, and gain actionable insights.'
  },
  {
    question: 'Can I try goalskeeper.io before purchasing?',
    answer:
      'Yes, we offer a 14-day free trial, no credit card is required, so you can explore all the features and see how goalskeeper.io can benefit your business. Start your free trial today and experience effortless data insights.'
  }
];

export const PRICING_FAQ = [
  {
    question: 'What are Users?',
    answer:
      'Users refer to the number of individual seats included in your plan. Each user gets access to the platform with their own login credentials, allowing team members to collaborate effectively. Each user can be assigned to different roles.'
  },
  {
    question: 'What are Data Source Connections?',
    answer:
      'Data Source Connections are the integrations with external platforms like Google Analytics, Mailchimp, and YouTube Analytics. These connections allow goalskeeper.io to import and analyze data from various marketing tools.'
  },
  {
    question: 'What are Researches?',
    answer:
      'Researches in goalskeeper.io are predefined sets of metrics and dimensions tailored for deep-dive analysis. They simplify the process of understanding your data by grouping relevant data points together, allowing you to explore and analyze specific aspects of your marketing performance.'
  },
  {
    question: 'What are Dashboards?',
    answer:
      'Dashboards are customizable views that compile selected metrics across different channels into a single, easy-to-read interface. They allow you to track your marketing performance in real-time and apply various filters to focus on the data that matters most.'
  },
  {
    question: 'What are Goals?',
    answer:
      'Goals are target values that you can set for your marketing metrics. goalskeeper.io tracks your progress towards these goals, providing insights and alerts to help you stay on track and achieve your objectives.'
  },
  {
    question: 'What is Historical Data?',
    answer:
      'Historical Data refers to the amount of past data goalskeeper.io retains from your connected data sources. Depending on your plan, you can access several months of historical data to analyze trends and make informed decisions based on past performance.'
  },
  {
    question: 'What happens after my 14-day free trial ends?',
    answer:
      'After your 14-day free trial, you can choose a plan that best suits your needs. If you do not select a plan, your account will be freeze.'
  },
  {
    question: 'How is my data protected with goalskeeper.io?',
    answer:
      'We take data security seriously. All data is encrypted and stored securely. We comply with industry standards to ensure your data is protected.'
  }
];
