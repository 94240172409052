import classnames from 'classnames';
import { TEXT_VARIANTS } from 'constants/text';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import TextComponent from 'style-book/text/TextComponent';

import './SwitchComponent.scss';

function SwitchComponent({
  className,
  labelClassName,
  text,
  value,
  labelEnd,
  onValueChange,
  nagtiveLabel,
  positiveLabel
}) {
  const option1Ref = useRef();
  const option2Ref = useRef();
  const [buttonWidth, setButtonWidth] = useState('fit-content');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (value) {
      setIsChecked(value);
    }
  }, [value]);

  useEffect(() => {
    if (!option1Ref.current || !option2Ref.current) return;

    const option1Width = option1Ref.current.clientWidth;
    const option2Width = option2Ref.current.clientWidth;
    setButtonWidth(Math.max(option1Width, option2Width) + 6);
  }, [option1Ref, option2Ref]);

  const onCheckedValueChange = (newCheckedValue) => {
    if (newCheckedValue !== isChecked) {
      setIsChecked(newCheckedValue);
      if (onValueChange) {
        onValueChange(newCheckedValue);
      }
    }
  };

  return (
    <div className={classnames('switch-component', className)}>
      {text && !labelEnd && (
        <TextComponent className={labelClassName} variant={TEXT_VARIANTS.PARAGRAPH_MEDIUM}>
          {text}
        </TextComponent>
      )}
      <div className="switch-component-content">
        <button
          ref={option1Ref}
          style={{ width: buttonWidth }}
          className={classnames('switch-option', 'option1', { checked: !isChecked })}
          onClick={() => onCheckedValueChange(false)}>
          {nagtiveLabel}
        </button>
        <button
          ref={option2Ref}
          style={{ width: buttonWidth }}
          className={classnames('switch-option', 'option2', { checked: isChecked })}
          onClick={() => onCheckedValueChange(true)}>
          {positiveLabel}
        </button>
      </div>
      {text && labelEnd && (
        <TextComponent className={labelClassName} variant={TEXT_VARIANTS.PARAGRAPH_MEDIUM}>
          {text}
        </TextComponent>
      )}
    </div>
  );
}

SwitchComponent.propTypes = {
  nagtiveLabel: PropTypes.string,
  positiveLabel: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.bool,
  labelEnd: PropTypes.bool,
  onValueChange: PropTypes.func
};

SwitchComponent.defaultProps = {
  nagtiveLabel: 'No',
  positiveLabel: 'Yes',
  defaultChecked: false
};

export default SwitchComponent;
