import Logger from 'analytics/Logger';
import classnames from 'classnames';
import { BUTTONS } from 'constants/analytics-events';
import { TEXT_VARIANTS } from 'constants/text';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AddIcon from 'style-book/icons/AddIcon';
import EmailIcon from 'style-book/icons/EmailIcon';
import FacebookIcon from 'style-book/icons/FacebookIcon';
import MinusIcon from 'style-book/icons/MinusIcon';
import TwitterIcon from 'style-book/icons/TwitterIcon';
import LinkComponent from 'style-book/link/LinkComponent';
import TextComponent from 'style-book/text/TextComponent';

import './FaqWidgetItem.scss';

const PLACEMENT = 'faq-answer';

function FaqWidgetItem({ question, answer, moreInfo }) {
  const [showAnswer, setShowAnswer] = useState(false);
  const Icon = showAnswer ? MinusIcon : AddIcon;

  const onIconClick = () => {
    Logger.buttonClicked(BUTTONS.FAQ_ITEM, 'faq', question);
    setShowAnswer(!showAnswer);
  };

  const onMoreInfoClick = () => {
    Logger.buttonClicked(BUTTONS.VIDEO_LINK, `faq-${question}`, moreInfo);
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="faq-item">
      <div className="item-header">
        <TextComponent
          className={classnames('question', { 'answer-opened': showAnswer })}
          variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
          {question}
        </TextComponent>
        <Icon
          containerClassName="icon-container"
          iconClassName={classnames('open-answer-icon', { 'answer-opened': showAnswer })}
          size="small"
          onClick={onIconClick}
        />
      </div>
      <div
        className={classnames('answer-container', {
          'answer-shown': showAnswer,
          'answer-hidden': !showAnswer
        })}>
        <TextComponent className="answer" variant={TEXT_VARIANTS.PARAGRAPH_MEDIUM}>
          {answer}
          {moreInfo && (
            <TextComponent className="answer" variant={TEXT_VARIANTS.PARAGRAPH_MEDIUM}>
              {' For more info checkout '}
              <TextComponent
                className="answer"
                variant={TEXT_VARIANTS.LINK}
                href={moreInfo}
                target="_blank"
                onClick={onMoreInfoClick}>
                click here
              </TextComponent>
            </TextComponent>
          )}
        </TextComponent>
        <div className="answer-help-container">
          <TextComponent className="answer" variant={TEXT_VARIANTS.PARAGRAPH_MEDIUM}>
            Still have questions? Ask us through any of the following channels:
          </TextComponent>
          <div className="channels">
            <LinkComponent className="social-link" url="mailto:success@goalskeeper.io">
              <EmailIcon
                size="big"
                onClick={() => Logger.buttonClicked(BUTTONS.CONTACT_US, PLACEMENT)}
                invert={false}
              />
            </LinkComponent>
            <LinkComponent className="social-link" url="https://twitter.com/goalskeeper_io">
              <TwitterIcon
                size="big"
                onClick={() => Logger.buttonClicked(BUTTONS.TWITTER, PLACEMENT)}
                invert={false}
              />
            </LinkComponent>
            <LinkComponent
              className="social-link"
              url="https://www.facebook.com/goalskeeper.io.achieving.business.goals">
              <FacebookIcon
                size="big"
                onClick={() => Logger.buttonClicked(BUTTONS.FACEBOOK, PLACEMENT)}
                invert={false}
              />
            </LinkComponent>
          </div>
        </div>
      </div>
    </div>
  );
}

FaqWidgetItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  moreInfo: PropTypes.string
};

export default FaqWidgetItem;
