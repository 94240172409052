import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function AddIcon(props) {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path
        d="M8.99687 1.79999V8.99999M8.99687 8.99999V16.2M8.99687 8.99999H1.79688M8.99687 8.99999H16.1969"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export default AddIcon;
