import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './DividerComponent.scss';

function DividerComponent({ className }) {
  return <hr className={classnames('divider-component', className)} />;
}

DividerComponent.propTypes = {
  className: PropTypes.string
};

export default DividerComponent;
