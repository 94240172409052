export const PRICING_PLANS = Object.freeze({
  STARTER: {
    id: 'starter',
    title: 'Starter',
    description:
      'Ideal for small businesses looking to streamline their marketing efforts with minimal time investment. Get on the right track with simplified tracking and insights.',
    basePrice: 14,
    color: 'graph5-color',
    users: 1,
    dataSources: 3,
    researches: 10,
    dashboards: 1,
    goals: 10,
    historicalData: 2,
    dataSyncFrequency: 24
  },
  PROFESSIONAL: {
    id: 'professional',
    title: 'Professional',
    description:
      'Tailored for digital marketing professionals seeking comprehensive research and reporting across multiple channels. Unlock advanced analytics tools to optimize your strategies.',
    basePrice: 30,
    color: 'graph9-color',
    users: 3,
    dataSources: 5,
    researches: 20,
    dashboards: 5,
    goals: 20,
    historicalData: 6,
    dataSyncFrequency: 24
  },
  TEAM: {
    id: 'team',
    title: 'Team',
    description:
      'Designed for teams of digital marketers with diverse responsibilities. Collaborate efficiently and excel in your respective areas with customizable data insights and sharing capabilities.',
    basePrice: 50,
    color: 'graph12-color',
    users: 10,
    dataSources: 10,
    researches: 50,
    dashboards: 20,
    goals: 50,
    historicalData: 12,
    dataSyncFrequency: 24
  }
});

export const PRICING_PERIODS = Object.freeze({
  MONTHLY: 'month',
  YEARLY: 'year'
});
