import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './ReviewsComponent.scss';

function ReviewsComponent({ className }) {
  return (
    <div className={classNames('reviews-component', className)}>
      <a href="https://www.capterra.com/reviews/275745/goalskeeperio?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
        {' '}
        <img
          className="review-image"
          border="0"
          src="https://assets.capterra.com/badge/4d76351d42a408f73650c1575f927620.svg?v=2224425&p=275745"
        />{' '}
      </a>
      <a href="https://www.getapp.com/hr-employee-management-software/a/goalskeeper-io/reviews/">
        {' '}
        <img
          className="review-image"
          border="0"
          src="https://www.getapp.com/ext/reviews_widget/v1/dark/goalskeeper-io-application"
        />{' '}
      </a>
      <a href="https://www.softwareadvice.com//goalskeeper-io-profile">
        {' '}
        <img
          className="review-image"
          border="0"
          src="https://badges.softwareadvice.com/reviews/90aea431-b62c-4fd8-9470-9699dfe4bf05"
        />{' '}
      </a>
    </div>
  );
}

ReviewsComponent.propTypes = {
  className: PropTypes.string
};

export default ReviewsComponent;
