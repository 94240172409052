import React from 'react';
import SvgIcon from 'style-book/icons/svg-icon/SvgIcon';

function InfoIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 25" fill="none" {...props}>
      <path
        d="M11.4885 17.25H12.623V11.5H11.4885V17.25ZM11.9995 9.9C12.1915 9.9 12.353 9.83695 12.4841 9.71085C12.6152 9.58477 12.6807 9.42603 12.6807 9.23463C12.6807 9.03546 12.6153 8.86964 12.4846 8.73718C12.3538 8.60471 12.1924 8.53848 12.0004 8.53848C11.8021 8.53848 11.6389 8.60471 11.5111 8.73718C11.3832 8.86964 11.3192 9.03546 11.3192 9.23463C11.3192 9.42603 11.3846 9.58477 11.5154 9.71085C11.6462 9.83695 11.8076 9.9 11.9995 9.9ZM12.0083 22C10.6924 22 9.45991 21.7506 8.31088 21.252C7.16184 20.7533 6.15555 20.0724 5.292 19.2092C4.42843 18.346 3.74721 17.3395 3.24833 16.1898C2.74944 15.04 2.5 13.8065 2.5 12.4892C2.5 11.1782 2.74933 9.94603 3.248 8.79255C3.74667 7.63907 4.42758 6.63472 5.29075 5.7795C6.15393 4.92427 7.16041 4.24721 8.31017 3.74833C9.45994 3.24944 10.6935 3 12.0108 3C13.3217 3 14.5539 3.24933 15.7074 3.748C16.8609 4.24667 17.8652 4.92342 18.7205 5.77825C19.5757 6.6331 20.2527 7.63834 20.7516 8.79398C21.2505 9.94959 21.5 11.1822 21.5 12.4917C21.5 13.8076 21.2506 15.04 20.752 16.1891C20.2533 17.3381 19.5765 18.3429 18.7217 19.2034C17.8669 20.0638 16.8616 20.7451 15.706 21.247C14.5504 21.749 13.3178 22 12.0083 22ZM12.0125 20.8654C14.3323 20.8654 16.3045 20.0506 17.9288 18.4211C19.5532 16.7916 20.3654 14.8138 20.3654 12.4875C20.3654 10.1676 19.5547 8.19549 17.9335 6.57113C16.3123 4.94678 14.3344 4.1346 12 4.1346C9.68203 4.1346 7.70831 4.94522 6.07883 6.56645C4.44934 8.18768 3.6346 10.1655 3.6346 12.5C3.6346 14.8179 4.44934 16.7916 6.07883 18.4211C7.70831 20.0506 9.68619 20.8654 12.0125 20.8654Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default InfoIcon;
