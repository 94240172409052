import classNames from 'classnames';
import { ANALYTICS_EVENTS } from 'constants/analytics-events';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonComponent from 'style-book/button/ButtonComponent';

import './FreeTrialButtonComponent.scss';

function FreeTrialButtonComponent({ className, info }) {
  const logGetStarted = () => {
    Logger.log(ANALYTICS_EVENTS.GET_STARTED, { info });
  };

  return (
    <div className={classNames('free-trial-button-component', className)}>
      <a className="get-started" href={`https://app.goalskeeper.io/signup`}>
        <ButtonComponent variant="primary" onClick={logGetStarted}>
          Start Free Trial
        </ButtonComponent>
      </a>
    </div>
  );
}

FreeTrialButtonComponent.propTypes = {
  className: PropTypes.string,
  info: PropTypes.string
};

export default FreeTrialButtonComponent;
