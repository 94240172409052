import { TEXT_VARIANTS } from 'constants/text';
import PropTypes from 'prop-types';
import React from 'react';
import DividerComponent from 'style-book/divider/DividerComponent';
import TextComponent from 'style-book/text/TextComponent';
import FaqWidgetItem from 'widgets/faq/FaqWidgetItem';
import FreeTrialButtonComponent from 'widgets/free-trial/FreeTrialButtonComponent';

import './FaqWidget.scss';

function FaqWidget({ faq }) {
  return (
    <div className="faq-section">
      <div className="faq-content">
        <TextComponent className="faq-title" variant={TEXT_VARIANTS.H2}>
          Frequently Asked Questions
        </TextComponent>
        <div className="faq-list">
          {faq.map((row, index) => (
            <React.Fragment key={row.question}>
              <FaqWidgetItem question={row.question} answer={row.answer} />
              {index < faq.length - 1 && <DividerComponent />}
            </React.Fragment>
          ))}
        </div>
        <FreeTrialButtonComponent info="faq" />
      </div>
    </div>
  );
}

FaqWidget.propTypes = {
  faq: PropTypes.array
};

export default FaqWidget;
