import Logger from 'analytics/Logger';
import classnames from 'classnames';
import { ANALYTICS_EVENTS } from 'constants/analytics-events';
import { PRICING_PERIODS, PRICING_PLANS } from 'constants/pricing';
import { TEXT_VARIANTS } from 'constants/text';
import { TOOLTIP_DIRECTIONS } from 'constants/tooltip';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import ButtonComponent from 'style-book/button/ButtonComponent';
import InfoIcon from 'style-book/icons/InfoIcon';
import TextComponent from 'style-book/text/TextComponent';
import { getStoredLandingPage } from 'utils/lp-utils';

import './PrincingPlanWidget.scss';

function PrincingPlanWidget({
  id,
  title,
  description,
  basePrice,
  color,
  period,
  users,
  dataSources,
  researches,
  dashboards,
  goals,
  historicalData
}) {
  const ref = useRef();
  const lpService = getStoredLandingPage();
  const price = period === PRICING_PERIODS.YEARLY ? basePrice * 10 : basePrice;

  useEffect(() => {
    if (!ref.current) return;

    ref.current.style.setProperty('--plan-color', `var(--${color})`);
  }, [ref.current, color]);

  const logChoosenPlan = () => {
    Logger.log(ANALYTICS_EVENTS.CHOOSE_PLAN, {
      name: id,
      info: lpService.getPath(),
      type: period
    });
  };

  return (
    <div
      ref={ref}
      className={classnames('pricing-plan-widget', {
        'main-plan': id === PRICING_PLANS.PROFESSIONAL.id
      })}>
      <TextComponent className="most-popular" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
        Most popular
      </TextComponent>
      <TextComponent className="plan-name" variant={TEXT_VARIANTS.H3}>
        {title}
      </TextComponent>
      <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>{description}</TextComponent>
      <div className="plan-price">
        <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>{'$'}</TextComponent>
        <TextComponent variant={TEXT_VARIANTS.H2}>{price}</TextComponent>
        <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>{`/${period}`}</TextComponent>
      </div>
      <a
        href={`https://app.goalskeeper.io/signup?type=${id}&period=${period}&lp=${lpService.getPath()}`}>
        <ButtonComponent className="strt-free-trial" variant="outline" onClick={logChoosenPlan}>
          Start free trial
        </ButtonComponent>
      </a>
      <div className="resources">
        <div className="resource-item">
          <div className="resource-quantity">
            <TextComponent className="resource" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              {users}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>Users</TextComponent>
          </div>
          <InfoIcon
            size="small"
            tooltip="Users refers to the number of team members or seats included in your plan. Each user can access and utilize goalsKeeper.io's powerful features and insights to enhance your digital marketing efforts."
            tooltipDirection={TOOLTIP_DIRECTIONS.TOP}
          />
        </div>
        <div className="resource-item">
          <div className="resource-quantity">
            <TextComponent className="resource" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              {dataSources}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>Data Source connections</TextComponent>
          </div>
          <InfoIcon
            size="small"
            tooltip="Data Source Connections represent the number of integrations available within goalsKeeper.io, allowing you to seamlessly connect with platforms like Google Analytics, Mailchimp, and YouTube Analytics. Access valuable data from multiple sources to gain comprehensive insights."
            tooltipDirection={TOOLTIP_DIRECTIONS.TOP}
          />
        </div>
        <div className="resource-item">
          <div className="resource-quantity">
            <TextComponent className="resource" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              {researches}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>Researches</TextComponent>
          </div>
          <InfoIcon
            size="small"
            tooltip="Researches simplify data analysis by grouping relevant metrics and dimensions into predefined sets, tailored for digital marketers without extensive data analysis expertise. Dive deep into your marketing data effortlessly and uncover actionable insights."
            tooltipDirection={TOOLTIP_DIRECTIONS.TOP}
          />
        </div>
        <div className="resource-item">
          <div className="resource-quantity">
            <TextComponent className="resource" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              {dashboards}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>Dashboards</TextComponent>
          </div>
          <InfoIcon
            size="small"
            tooltip="Dashboards provide cross-channel visibility by showcasing selected metrics and key performance indicators in a visually engaging format. Customize your dashboards and apply filters to track your marketing performance effectively."
            tooltipDirection={TOOLTIP_DIRECTIONS.TOP}
          />
        </div>
        <div className="resource-item">
          <div className="resource-quantity">
            <TextComponent className="resource" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              {goals}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>Goals</TextComponent>
          </div>
          <InfoIcon
            size="small"
            tooltip="Goals allow you to set target values for your marketing data, providing a clear benchmark for success. Gain insights into your goal progress and stay on track towards achieving your business objectives."
            tooltipDirection={TOOLTIP_DIRECTIONS.TOP}
          />
        </div>
        <div className="resource-item">
          <div className="resource-quantity">
            <TextComponent className="resource" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              {`${historicalData} months`}
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>Historical Data</TextComponent>
          </div>
          <InfoIcon
            size="small"
            tooltip="Historical Data refers to the number of months worth of data stored within goalsKeeper.io from your connected data sources. Access past performance metrics and trends to make informed decisions and drive future strategies."
            tooltipDirection={TOOLTIP_DIRECTIONS.TOP}
          />
        </div>
      </div>
      <a
        className="signup"
        href={`https://app.goalskeeper.io/signup?type=${id}&period=${period}&lp=${lpService.getPath()}`}>
        <ButtonComponent className="buy-button" variant="primary" onClick={logChoosenPlan}>
          {`Buy ${title}`}
        </ButtonComponent>
      </a>
    </div>
  );
}

PrincingPlanWidget.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  promotion: PropTypes.string
};

export default PrincingPlanWidget;
